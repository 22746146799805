.value-field-container{
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    border: 1.5px solid rgba(0, 0, 0, 0.192);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.value-field-label{
    position: absolute;
    top: -11px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.450);
    font-size: 13px;
}
.value-field-container p {
    max-width: 100%; /* The container will expand to the full width of its parent */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide the overflowed text */
    text-overflow: ellipsis; /* Show ellipsis when text overflows */
    color: rgba(0, 0, 0, 0.650);
}
.value-field-container a{
    color: rgba(0, 0, 0, 0.650);
    text-decoration: none;
    cursor: pointer;
}
.value-field-container a:hover{
    color: rgba(220, 20, 60, 0.616);
}
.value-field-container a:hover span{
    color: rgba(0, 0, 0, 0.650);
}
.value-field-container a span{
    margin-left: 5px;
}